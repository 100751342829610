import React from 'react';
import ExcelUploader from './components/ExcelUploader2';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>润杰包装牛逼开票转换器</h1>
        <ExcelUploader />
      </header>
    </div>
  );
}

export default App;