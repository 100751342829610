// src/ExcelUploader.js
import React, { useState } from "react";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

const ExcelUploader = () => {
  const [files, setFiles] = useState([]);
  const [processedFiles, setProcessedFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);

  const toFixed2 = (num) => {
    return Number(num.toString().match(/^\d+(?:\.\d{0,2})?/));
  };
  const [totals, setTotals] = useState([]);

  const handleFileProcess = () => {
    setTotals([]);
    const processed = [];
    Array.from(files).forEach((file, index) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        let json = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        try {
          const firstRow = [
            "序号 \\r\\n（必填；只允许输入数字，且按序排列）\\r\\n注：红色字体备注",
            "货物名称\\r\\n（必填）",
            "计量单位\\r\\n（选填）",
            "规格型号\\r\\n（选填）",
            "数量\\r\\n（含税单价有值时，数量、金额至少1项必填）",
            "单价（数量有值时，含税单价、金额至少1项必填）",
            "税率（\\r\\n必填；如果开具免税税率填0，不要填写免税）",
            "折扣金额（有折扣时与折扣率两者必填其一）",
            "折扣税额（没有折扣不要填写）",
            "折扣率（有折扣时与折扣金额两者必填其一）",
            "金额",
            "商品编码\\r\\n（建议填写，不填智能赋码）",
            "企业商品编码\\r\\n（选填）",
            "使用优惠政策标识\\r\\n（使用为1，不使用为0）",
            "免税类型\\r\\n（不使用为空即可）\\r\\n（使用填写序号，如1，不要填写名称）\\r\\n1，出口免税和其他免税优惠政策\\r\\n2，不征税\\r\\n3，普通零税率",
            "增值税特殊管理\\r\\n（优惠政策标识为1时填入优惠政策说明，如：不征税（填写名称，不要填写序号）\\r\\n1.不征税\\r\\n2.50%先征后退\\r\\n3.稀土产品\\r\\n4.按5%简易征收减按1.5%计征\\r\\n5.先征后退\\r\\n6.按5%简易征收\\r\\n7.简易征收\\r\\n8.即征即退70%\\r\\n9.免税\\r\\n10.按3%简易征收\\r\\n11.超税负3%即征即退\\r\\n12.超税负12%即征即退\\r\\n13.即征即退30%\\r\\n14.超税负8%即征即退\\r\\n15.100%先征后退\\r\\n16.即征即退50%\\r\\n17.即征即退100%）  ",
          ];

          console.log(json);
          let after = [];
          // 在这里进行数据处理
          for (let i = 0; i < json.length; i++) {
            if (i === 3) {
              after.push(firstRow);
            } else if (
              i >= 4 &&
              (json[i][3] !== undefined ||
                json[i][4] !== undefined ||
                json[i][5] !== undefined)
            ) {
              after.push(json[i]);
            }
          }
          console.log("之中~~~~~~~~~~~~~~~~~~", after);

          after = after.map((row, index) => {
            if (index === 0) {
              return row;
            } else {
              // IF(ISBLANK(F5),D5&"*"&E5,D5&"*"&E5&"*"&F5)
              const temp = [index, undefined, "个"];
              // =IF(ISBLANK(B5),C5,IF(ISBLANK(F5),"三层衬板",(IF(B5=5,"五层纸箱",IF(B5=3,"三层纸箱",IF(B5=7,"七层纸箱"))))))
              temp[1] =
                row[1] === undefined
                  ? row[2]
                  : row[5] === undefined
                  ? "三层衬板"
                  : row[1] === 5
                  ? "五层纸箱"
                  : row[1] === 3
                  ? "三层纸箱"
                  : row[1] === 7
                  ? "七层纸箱"
                  : "";
              temp[3] = row[5]
                ? `${row[3] + "*" + row[4] + "*" + row[5]}`
                : `${row[3] + "*" + row[4]}`;
              temp[4] = row[6];
              // console.log(row);
              temp[5] = toFixed2(row[7], 2);
              temp[6] = "0.13";
              temp[11] = "1060105010000000000";
              // console.log(index + 1 + "~~~~~~~~~~~~", temp);
              return temp;
            }
          });

          const total = after.reduce((acc, row, index) => {
            if (index === 0) return 0;
            return acc + (row[5] * 100 * row[4]) / 100;
          }, 0);
          setTotals((prev) => [...prev, toFixed2(total)]);

          const newWorksheet = XLSX.utils.aoa_to_sheet(after);
          const newWorkbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(newWorkbook, newWorksheet, "Sheet1");
          const wbout = XLSX.write(newWorkbook, {
            bookType: "xlsx",
            type: "array",
          });

          processed.push({
            // name: `${file.name.replace(/\.[^.]+$/, "")}总价${total}.xlsx`,
            name: `开票_${file.name.replace(/\.[^.]+$/, ".xlsx")}`,
            blob: new Blob([wbout], { type: "application/octet-stream" }),
          });

          // 当所有文件都处理完后，更新状态
          if (processed.length === files.length) {
            setProcessedFiles(processed);
          }
        } catch (error) {
          alert(file.name+"文件格式错误，请检查文件格式然后重试");
          window.location.reload();
        }
      };
      reader.readAsArrayBuffer(file);
    });
  };

  const handleDownload = (blob, filename) => {
    saveAs(blob, filename);
  };

  const handleShowFile = (blob) => {
    setSelectedFile(blob);
  };

  const handleCloseFile = () => {
    setSelectedFile(null);
  };
  const handleFileUpload = (event) => {
    setFiles(event.target.files);
    setProcessedFiles([]); // Reset processed files when new files are uploaded
  };

  return (
    <div>
      <input
        type="file"
        accept=".xls,.xlsx"
        multiple
        onChange={handleFileUpload}
      />
      <button onClick={handleFileProcess}>处理文件</button>
      <table>
        {!!processedFiles.length && (
          <tr>
            <th>文件名</th>
            <th>总价</th>
            <th>操作</th>
          </tr>
        )}
        {processedFiles.map((file, index) => (
          <tr key={index}>
            <td
              onClick={() => handleShowFile(file.blob)}
              style={{ cursor: "pointer" }}
            >
              {file.name}
            </td>
            <td>{totals[index]}元</td>
            <td>
              <span>
                <button onClick={() => handleDownload(file.blob, file.name)}>
                  下载
                </button>
              </span>
            </td>
          </tr>
        ))}
      </table>
      {selectedFile && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={handleCloseFile}>
              &times;
            </span>
            <h2>转换好的文件</h2>
            <button
              onClick={() => handleDownload(selectedFile, selectedFile.name)}
            >
              转换文件
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ExcelUploader;
